export enum URLS {
  ROOT_PATH = '/',
  LOGIN_PATH = '/login',
  REGISTER_PATH = '/register',
  DASHBOARD_PATH = '/dashboard',
  USERS_MANAGEMENT_PATH = '/users-management',
  INCIDENTS_MANAGEMENT_PATH = '/incidents-management',
  WILDLIFE_TRAILS_PATH = '/wildlife-trails',
  MYPROFILE_PATH = '/profile',
  RESET_PASSWORD_PATH = '/reset-password',
  PAGE_500 = '/internal-server-error-page-500',
  TRAILS_IMAGES_PATH = '/wildlife-database',
}
