export enum UserRolesEnum {
  ALL = -1,
  ADMIN = 1,
  LEADER = 2,
  USER = 3,
}

export const userRolesText: Record<UserRolesEnum | any, string> = {
  [UserRolesEnum.ALL]: 'userRoles.enum.all',
  [UserRolesEnum.ADMIN]: 'userRoles.enum.admin',
  [UserRolesEnum.LEADER]: 'userRoles.enum.leader',
  [UserRolesEnum.USER]: 'userRoles.enum.user',
};
