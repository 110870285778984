import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageHelper, ToasterHelper } from '../helpers';
import { UserService } from '../services';
import { URLS } from 'src/app/shared/enums';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

export const AuthGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const userService = inject(UserService);
  const messageService = inject(MessageService);
  const translateService = inject(TranslateService);

  if (!LocalStorageHelper.isRefreshTokenValid()) {
    LocalStorageHelper.removeTokensFromLocalStorage();
    userService.resetCurrentUser();
    router.navigate([URLS.LOGIN_PATH]);
    messageService.add(
      ToasterHelper.errorMessage(
        translateService.instant('sessionExpiredMessage')
      )
    );
    return false;
  }

  return true;
};
