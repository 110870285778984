import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, switchMap } from 'rxjs';
import { URLS } from 'src/app/shared/enums';
import { UserApi } from '../api';
import { LocalStorageHelper } from '../helpers';
import { GetRefreshTokenPayload } from '../interfaces';
import { UserService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshTokenHappening: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private userApi: UserApi
  ) {}
  intercept(
    req: HttpRequest<any>,
    handler: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url === '/api/auth/refresh-token') {
      return handler.handle(req);
    }

    return from(this.handleRequestToken(req)).pipe(
      switchMap((request) => handler.handle(request))
    );
  }

  private async handleRequestToken(request: HttpRequest<any>) {
    if (LocalStorageHelper.isJwtTokenValid()) return this.addToken(request);

    if (LocalStorageHelper.isRefreshTokenValid()) {
      if (this.isRefreshTokenHappening) {
        return this.addToken(request);
      } else {
        this.isRefreshTokenHappening = true;
      }

      const getRefreshTokenPayload: GetRefreshTokenPayload = {
        refreshToken: LocalStorageHelper.getRefreshToken(),
        userId: LocalStorageHelper.getUserId(),
      };
      await this.userApi.getJwtToken(getRefreshTokenPayload);
      this.isRefreshTokenHappening = false;
      return this.addToken(request);
    }

    if (
      !LocalStorageHelper.isRefreshTokenValid() &&
      LocalStorageHelper.getJwtToken() &&
      LocalStorageHelper.getRefreshToken()
    ) {
      LocalStorageHelper.removeTokensFromLocalStorage();
      this.userService.resetCurrentUser();
      this.router.navigate([URLS.LOGIN_PATH]);
    }

    return request;
  }

  private addToken(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${LocalStorageHelper.getJwtToken()}`,
      },
    });
  }
}
