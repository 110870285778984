import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageHelper } from '../helpers';

export const BackToAuth: CanActivateFn = async () => {
  const router = inject(Router);

  if (
    LocalStorageHelper.isJwtTokenValid() &&
    LocalStorageHelper.isRefreshTokenValid()
  ) {
    router.navigate(['']);
    return false;
  }

  return true;
};
