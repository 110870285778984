import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/@core/api';
import { URLS } from 'src/app/shared/enums';
import {
  LoginPayloadInterface,
  LoginResponseInterfaceDTO,
  RegisterPayloadInterface,
  ResetPasswordRequestInterfaceDTO,
} from '../interfaces';
import { AuthMapper } from './auth.mapper';
import { ResetPasswordInterfaceDTO } from '../interfaces/reset-password.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthApi {
  private readonly path = '/auth';
  private readonly urls = URLS;

  constructor(private apiService: ApiService, private router: Router) {}

  login(
    userAccountForLogin: LoginPayloadInterface
  ): Promise<LoginResponseInterfaceDTO | null> {
    return new Promise((resolve) => {
      this.apiService
        .post(this.path + '/login', userAccountForLogin)
        .subscribe({
          next: (responseDTO) => {
            const response = AuthMapper.mapLogInDTOtoModel(responseDTO);
            resolve(response);
          },
          error: (err) => {
            if (err.status === 500) this.router.navigate([this.urls.PAGE_500]);
            resolve(null);
          },
        });
    });
  }

  register(userAccountForRegister: RegisterPayloadInterface): Observable<any> {
    return this.apiService.post(
      this.path + '/register',
      userAccountForRegister
    );
  }

  logout(userAccountRefreshToken: string): Promise<void | null> {
    return new Promise((resolve) => {
      return this.apiService
        .post(this.path + '/logout', { refreshToken: userAccountRefreshToken })
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            resolve(null);
          },
        });
    });
  }

  requestPasswordReset(
    resetPasswordRequestInterfaceDTO: ResetPasswordRequestInterfaceDTO
  ): Observable<void> {
    return this.apiService.post(
      this.path + '/request-password-reset',
      resetPasswordRequestInterfaceDTO
    );
  }

  async resetPassword(
    resetPasswordInterfaceDTO: ResetPasswordInterfaceDTO
  ): Promise<void | null> {
    return new Promise((resolve) => {
      return this.apiService
        .post(this.path + '/reset-password', resetPasswordInterfaceDTO)
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            resolve(null);
          },
        });
    });
  }
}
