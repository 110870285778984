import { Routes } from '@angular/router';
import { AuthGuard, OnlyAdminAccess, UserNoAccess } from '../@core/guards';

export const applicationChildRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('../features/dashboard/dashboard/dashboard.component').then(
        (mod) => mod.DashboardComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'users-management',
    loadComponent: () =>
      import(
        '../features/users-management/users-management/users-management.component'
      ).then((mod) => mod.UsersManagementComponent),
    canActivate: [AuthGuard, UserNoAccess],
  },
  {
    path: 'incidents-management',
    loadComponent: () =>
      import(
        '../features/incidents-management/incidents-management/incidents-management.component'
      ).then((mod) => mod.IncidentsManagementComponent),
    canActivate: [AuthGuard],
  },
  {
    path: 'wildlife-trails',
    loadComponent: () =>
      import(
        '../features/wildlife-trails/wildlife-trails/wildlife-trails.component'
      ).then((mod) => mod.WildlifeTrailsComponent),
    canActivate: [AuthGuard, OnlyAdminAccess],
  },
  {
    path: 'wildlife-database',
    loadComponent: () =>
      import(
        '../features/trails-images-management/trails-images-management.component'
      ).then((mod) => mod.TrailsImagesManagementComponent),
    canActivate: [AuthGuard, OnlyAdminAccess],
  },
  {
    path: 'profile',
    loadComponent: () =>
      import('../features/my-profile/my-profile/my-profile.component').then(
        (mod) => mod.MyProfileComponent
      ),
    canActivate: [AuthGuard],
  },
];
