import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { catchError, Observable } from 'rxjs';
import { ToasterHelper } from '../helpers';

@Injectable()
export class GlobalInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private messageService: MessageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    handler: HttpHandler
  ): Observable<HttpEvent<any>> {
    const translateService = this.injector.get(TranslateService);

    return handler.handle(req).pipe(
      catchError((error) => {
        this.messageService.add(
          ToasterHelper.errorMessage(
            error.error?.message ??
              translateService.instant('somethingWentWrongMessage')
          )
        );
        throw error;
      })
    );
  }
}
