import { Routes } from '@angular/router';
import { BackToAuth } from './@core/guards';
import { applicationChildRoutes } from './layout/application-child-routes.routes';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./features/auth/login/login.component').then(
        (mod) => mod.LoginComponent
      ),
    canActivate: [BackToAuth],
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./features/auth/register/register.component').then(
        (mod) => mod.RegisterComponent
      ),
    canActivate: [BackToAuth],
  },
  {
    path: 'reset-password',
    loadComponent: () =>
      import('./features/auth/reset-password/reset-password.component').then(
        (mod) => mod.ResetPasswordComponent
      ),
    canActivate: [BackToAuth],
  },
  {
    path: 'internal-server-error-page-500',
    loadComponent: () =>
      import(
        './shared/components/server-error-page/server-error-page.component'
      ).then((mod) => mod.ServerErrorPageComponent),
  },
  {
    path: '',
    loadComponent: () =>
      import('./layout/app-layout/app-layout.component').then(
        (mod) => mod.AppLayoutComponent
      ),
    children: applicationChildRoutes,
  },
  {
    path: '**',
    loadComponent: () =>
      import('./shared/components/no-found-page/no-found-page.component').then(
        (mod) => mod.NoFoundPageComponent
      ),
  },
];
