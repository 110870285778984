import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class GoogleMapsService {
  private isScriptLoaded = false;

  constructor() {}

  loadGoogleMaps(): Promise<void> {
    // Check if the script is already in the document
    if (this.isScriptLoaded || document.getElementById('google-maps-script')) {
      return Promise.resolve(); // Return early if the script is already loaded
    }

    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}`;
    script.async = true;
    script.defer = true;
    script.id = 'google-maps-script'; // Set a unique ID to check if the script is already added

    return new Promise((resolve, reject) => {
      script.onload = () => {
        this.isScriptLoaded = true;
        resolve();
      };
      script.onerror = () => reject(new Error('Google Maps script load error'));
      document.head.appendChild(script);
    });
  }
}
