export enum IncidentStatusEnum {
  ALL = -1,
  CLOSED = 1,
  OPEN,
}

export const incidentStatusText: Record<IncidentStatusEnum, string> = {
  [IncidentStatusEnum.ALL]: 'incidentStatus.enum.all',
  [IncidentStatusEnum.OPEN]: 'incidentStatus.enum.open',
  [IncidentStatusEnum.CLOSED]: 'incidentStatus.enum.closed',
};

export enum ReportTypesEnum {
  ALL = -1,
  SIGHTED_ANIMAL = 1,
  LOSS,
}

export const reportTypesText: Record<ReportTypesEnum | number, string> = {
  [ReportTypesEnum.ALL]: 'reportTypes.enum.all',
  [ReportTypesEnum.SIGHTED_ANIMAL]: 'reportTypes.enum.sightedAnimal',
  [ReportTypesEnum.LOSS]: 'reportTypes.enum.loss',
};

export enum LossTypeEnum {
  CROPS = 1,
  HOME,
  INFRASTRUCTURE_DAMAGE,
  LIVESTOCK_LOSS,
  OTHER,
  POWER,
  SPECIES_AND_NUMBER,
  WATERPOINT,
}

export const lossTypeText: Record<LossTypeEnum, string> = {
  [LossTypeEnum.CROPS]: 'lossType.enum.crops',
  [LossTypeEnum.HOME]: 'lossType.enum.home',
  [LossTypeEnum.INFRASTRUCTURE_DAMAGE]: 'lossType.enum.infrastructure_damage',
  [LossTypeEnum.LIVESTOCK_LOSS]: 'lossType.enum.loss',
  [LossTypeEnum.OTHER]: 'lossType.enum.other',
  [LossTypeEnum.POWER]: 'lossType.enum.power',
  [LossTypeEnum.SPECIES_AND_NUMBER]: 'lossType.enum.species_and_number',
  [LossTypeEnum.WATERPOINT]: 'lossType.enum.waterpoint',
};

export enum AnimalTypesEnum {
  ANTELOPE = 1,
  CHEETAH,
  ELEPHANT,
  LEOPARD,
  LION,
  RHINO,
  SNARE,
}

export const animalTypeText: Record<AnimalTypesEnum, string> = {
  [AnimalTypesEnum.ANTELOPE]: 'animalType.enum.antelope',
  [AnimalTypesEnum.CHEETAH]: 'animalType.enum.cheetah',
  [AnimalTypesEnum.ELEPHANT]: 'animalType.enum.elephant',
  [AnimalTypesEnum.LEOPARD]: 'animalType.enum.leopard',
  [AnimalTypesEnum.LION]: 'animalType.enum.lion',
  [AnimalTypesEnum.RHINO]: 'animalType.enum.rhino',
  [AnimalTypesEnum.SNARE]: 'animalType.enum.snare',
};
