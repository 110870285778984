import {
  CurrentUserResponseInterface,
  CurrentUserResponseInterfaceDTO,
} from '../interfaces';

export class UserDetailsMapper {
  public static mapUserDetailsDTOtoModel(
    userDetailsResponseDTO: CurrentUserResponseInterfaceDTO
  ): CurrentUserResponseInterface {
    return {
      id: userDetailsResponseDTO.id,
      name: userDetailsResponseDTO.name,
      email: userDetailsResponseDTO.email,
      phoneNumber: userDetailsResponseDTO.phoneNumber,
      role: userDetailsResponseDTO.role,
      imageProfile: userDetailsResponseDTO.imageProfile,
      needResetPassword: userDetailsResponseDTO.needResetPassword,
    };
  }
}
