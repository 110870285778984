import {
  LoginResponseInterface,
  LoginResponseInterfaceDTO,
  RegisterPayloadInterface,
  RegisterPayloadInterfaceDTO,
  ResetPasswordInterface,
  ResetPasswordInterfaceDTO,
  ResetPasswordRequestInterface,
  ResetPasswordRequestInterfaceDTO,
} from '../interfaces';

export class AuthMapper {
  public static mapLogInDTOtoModel(
    loginResponseDTO: LoginResponseInterfaceDTO
  ): LoginResponseInterface {
    return {
      accessToken: loginResponseDTO.accessToken,
      refreshToken: loginResponseDTO.refreshToken,
    };
  }

  public static mapRegisterDTOtoModel(
    registerPayloadInterface: RegisterPayloadInterface
  ): RegisterPayloadInterfaceDTO {
    return {
      name: registerPayloadInterface.name,
      email: registerPayloadInterface.email,
      phoneNumber: registerPayloadInterface.phoneNumber,
      password: registerPayloadInterface.password,
      community: registerPayloadInterface.community,
      livestock: registerPayloadInterface.livestock,
      occupation: registerPayloadInterface.occupation,
    };
  }

  public static mapResetPasswordRequestInterfaceModelToDTO(
    model: ResetPasswordRequestInterface
  ): ResetPasswordRequestInterfaceDTO {
    return {
      email: model.email,
      phoneNumber: model.phoneNumber,
    };
  }

  public static mapResetPasswordInterfaceModelToDTO(
    model: ResetPasswordInterface
  ): ResetPasswordInterfaceDTO {
    return {
      userUuid: model.userUuid,
      newPassword: model.newPassword,
      confirmNewPassword: model.confirmNewPassword,
    };
  }
}
