import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Option } from 'src/app/shared/enums';

export function enumToArray(enumObj: any): any[] {
  return Object.keys(enumObj)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({ label: key, value: enumObj[key] }));
}

export function translateEnumToOptions(
  enumObj: any,
  textObj: Record<any, string>,
  translateService: TranslateService
): Option[] {
  return enumToArray(enumObj)
    .filter((option) => option.value !== enumObj.NONE)
    .map((option) => ({
      ...option,
      label: translateService.instant(textObj[option.value]),
    }));
}

export function loadTranslatedOptions(
  translateService: TranslateService,
  community: any,
  communityText: Record<any, string>,
  occupation: any,
  occupationText: Record<any, string>,
  livestock: any,
  livestockText: Record<any, string>
) {
  return {
    communityOptions: translateEnumToOptions(
      community,
      communityText,
      translateService
    ),
    occupationOptions: translateEnumToOptions(
      occupation,
      occupationText,
      translateService
    ),
    livestockOptions: translateEnumToOptions(
      livestock,
      livestockText,
      translateService
    ),
  };
}

export function getUserPhone(
  event: string,
  userPhoneNumber: FormControl
): string {
  let userPhoneNumberCompleted = event + userPhoneNumber.value;
  return userPhoneNumberCompleted
    .replaceAll('-', '')
    .replaceAll('(', '')
    .replaceAll(')', '');
}

export function trimRequestBody(obj: any): any {
  if (typeof obj === 'string') {
    return obj.trim() || null;
  } else if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        obj[key] = trimRequestBody(obj[key]);
      }
    }
  }
  return obj;
}
