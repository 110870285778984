import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthMapper } from 'src/app/features/auth/core/api';
import { URLS } from 'src/app/shared/enums';
import { LocalStorageHelper } from '../helpers';
import {
  CurrentUserResponseInterfaceDTO,
  GetRefreshTokenPayload,
} from '../interfaces';
import { ApiService } from './api.service';
import { UserDetailsMapper } from './user.mapper';

@Injectable({
  providedIn: 'root',
})
export class UserApi {
  private readonly userPath = '/users';
  private readonly tokenPath = '/auth';
  private readonly urls = URLS;

  constructor(private apiService: ApiService, private router: Router) {}

  getCurrentUserDetails(): Promise<CurrentUserResponseInterfaceDTO | null> {
    return new Promise((resolve) => {
      this.apiService
        .get(this.userPath + '/get-current-user-details')
        .subscribe({
          next: (responseDTO) => {
            const response =
              UserDetailsMapper.mapUserDetailsDTOtoModel(responseDTO);
            LocalStorageHelper.saveUserIdToLocalStorage(response.id);
            resolve(response);
          },
          error: () => {
            LocalStorageHelper.removeTokensFromLocalStorage();
            this.router.navigate([this.urls.LOGIN_PATH]);
            resolve(null);
          },
        });
    });
  }

  getJwtToken(
    getRefreshTokenPayload: GetRefreshTokenPayload
  ): Promise<boolean> {
    return new Promise((resolve) => {
      this.apiService
        .post(`${this.tokenPath}/refresh-token`, getRefreshTokenPayload)
        .subscribe({
          next: (responseDTO) => {
            const response = AuthMapper.mapLogInDTOtoModel(responseDTO);
            LocalStorageHelper.saveTokensToLocalStorage(
              response.accessToken,
              response.refreshToken
            );
            resolve(true);
          },
          error: () => {
            resolve(false);
          },
        });
    });
  }
}
